import { BuildingClient } from "../../client/BuildingClient"
import {EventScheduleClient, EventType} from "../../client/EventScheduleClient"
import { useEffect, useState } from "react"
import { Building } from "../BuildingStatus"
import { MultipleBuildingInput } from "../MultipleBuildingInput"
import { Calendar } from "../calendar/Calendar"
import { useEntitlements } from "../../EntitlementProvider"
import { NotificationClient as NotificationClient } from "../../client/NotificationsClient"

export interface CalendarPageProps {
    buildingClient: BuildingClient
    eventClient: EventScheduleClient
    notificationClient: NotificationClient
    refreshTime: number | null
}
export const CalendarPage = ({ buildingClient, eventClient, notificationClient, refreshTime = null }: CalendarPageProps) => {
    const [eventTypes, setEventTypes] = useState<EventType[]>([])
    const [selectedBuildings, setSelectedBuildings] = useState<Building[]>([])
    const [buildings, setBuildings] = useState<Building[]>([])

    const entitlements = useEntitlements()

    useEffect(() => {
        buildingClient.retrieveBuildings().then(setBuildings)
    }, [buildingClient])

    useEffect(() => {
        const run = async () => {
            const eventTypes: EventType[] = await eventClient.getEventTypes()
            setEventTypes(eventTypes)
        }
        run()
    }, [eventClient, entitlements])

    return <div className={'container-calendar'}>
        <div style={{ marginBottom: '1rem' }}>
            <MultipleBuildingInput buildingsSource={buildings} onChange={setSelectedBuildings} />
        </div>
        <Calendar buildings={buildings} eventClient={eventClient} notificationClient={notificationClient} eventTypes={eventTypes} selectedBuildings={selectedBuildings} refreshTime={refreshTime} />
    </div>
}
