import Form from "react-bootstrap/Form"
import {Alert, Col, FloatingLabel, Row} from "react-bootstrap"
import FormGroup from "react-bootstrap/FormGroup"
import {PropsWithChildren, useEffect, useState} from "react"
import {BasicEntity, Deal, LocalDateOld, Meter, Mop, SubmissionModel, Supplier} from "../client/FormClient"
import "../shared.scss"
import {RequiredPrompt, SelectField} from "./common/SelectField"
import MultiSelect from "./common/MuiSelect"
import {useDebouncedCallback} from "use-debounce"
import {Organisation} from "../client/UserClient"
import equal from "deep-equal"
import {TransmissionFeeType} from "../client/BuildingClient";

export type DealModel = Deal

interface AddressModel {
    line1: string,
    line2?: string,
    city: string,
    postCode: string,
    county: string,
    country: string,
}

interface CustomerModel extends AddressModel {
    name: string,
}

interface CommercialContactModel {
    name: string,
    phone1: string,
    phone2?: string,
    email: string,
}

interface ContractModel {
    startDate: Date,
    endDate: Date,
    revenueShare: number,
}

interface BuildingModel extends AddressModel {
    name: string,
    orgIds: string[]
}

interface ControlStrategyModel {
    maxTotalDurationMinutes: number,
    maxRunDuration: number,
    maxNumEvents: number,
    saving: number,
    recoveryDuration: number,
    rampUpDownTime: number
}

interface TariffModel {
    transmissionFeeType?: TransmissionFeeType,
    supplierId?: number,
    startDate?: Date,
    endDate?: Date,
    pricePerKWh?: number
}

type MeterModel = Partial<Meter>

export interface OnboardingFormModel {
    deal: Partial<DealModel>,
    customer: Partial<CustomerModel>,
    commercialContact: Partial<CommercialContactModel>,
    contract: Partial<ContractModel>,
    building: Partial<BuildingModel>,
    controlStrategy: Partial<ControlStrategyModel>,
    tariff: TariffModel,
    meters: Array<MeterModel>,
}

interface OnboardingFormProps {
    handleSubmit: (event: SubmissionModel) => void
    retrieveDeals: () => Promise<Deal[]>
    retrieveSuppliers: () => Promise<Supplier[]>
    retrieveMops: () => Promise<Mop[]>
    retrieveOrgs: () => Promise<Organisation[]>
}

interface FormProps {
    onChange: (newState: object) => void
}

interface DealFormProps extends FormProps {
    deals: Deal[]
    deal?: Deal
}

interface CustomerFormProps extends FormProps {
    customer?: Partial<CustomerModel>
}

interface CommercialContactFormProps extends FormProps {
    commercialContact?: Partial<CommercialContactModel>
}

interface ContractFormProps extends FormProps {
    contract?: Partial<ContractModel>
}

interface BuildingFormProps extends FormProps {
    building?: Partial<BuildingModel>
    orgs: Organisation[]
}

interface ControlStrategyFormProps extends FormProps {
    controlStrategy?: Partial<ControlStrategyModel>
}

interface TariffFormProps extends FormProps {
    suppliers: Supplier[]
    tariff?: TariffModel
}

interface MeterFormProps extends FormProps {
    mops: Mop[]
    meter: MeterModel
    oneBasedIndex: number
}

interface MetersFormProps extends FormProps {
    mops: Mop[]
    meters: Array<MeterModel>
}

interface FieldProps {
    displayName: string
    name: string
    value?: string | number
    testId: string
    handleChange: (e: any) => void
    required?: boolean
}

interface DateFieldProps {
    displayName: string
    name: string
    value?: Date
    testId: string
    handleChange: (e: any) => void
    required?: boolean
}

export interface TextFieldProps extends FieldProps {
    required?: boolean
    disabled?: boolean
}

interface NumberFieldProps extends FieldProps {
    placeholder?: string
    step?: string
    min: string
    max?: string
}

interface FormSectionProps extends PropsWithChildren {
    title: string
}

const FormSection = ({ title, children }: FormSectionProps) => {
    return <FormGroup>
        <h4>{title}</h4>
        {children}
    </FormGroup>
}

const FormSubSection = ({ title, children }: FormSectionProps) => {
    return <FormGroup>
        <h5>{title}</h5>
        {children}
    </FormGroup>
}

const DateField = ({ displayName, value = undefined, name, testId, handleChange, required = true }: DateFieldProps) => {
    const stringValue = value ? (new LocalDateOld(value)).toJSON() : ''
    return <Form.Group as={Col}>
        <FloatingLabel label={displayName} className="mb-3">
            <Form.Control type={'date'}
                name={name}
                value={stringValue}
                data-testid={testId}
                onChange={handleChange}
                required={required}
            />
        </FloatingLabel>
        <RequiredPrompt required={required} fieldName={displayName} testId={testId} />
    </Form.Group>
}


export const NumberField = ({
    displayName,
    name,
    value = '',
    testId,
    min,
    max,
    step,
    handleChange,
    required = true
}: NumberFieldProps) => {
    const [inputValue, setInputValue] = useState<number | string>(value)

    useEffect(() => {
        value ? setInputValue(value) : {}
    }, [value])

    const debouncedHandleChange = useDebouncedCallback((e) => {
        handleChange(e)
    }, 400)

    const handleInputChange = (e) => {
        setInputValue(e.target.value)
        debouncedHandleChange(e)
    }

    return <>
        <FloatingLabel label={displayName} className="mb-3">
            <Form.Control
                name={name}
                value={inputValue}
                data-testid={testId}
                onChange={handleInputChange}
                placeholder={displayName}
                type={'number'}
                step={step}
                min={min}
                max={max}
                required

            />
        </FloatingLabel>
        <RequiredPrompt required={required} fieldName={displayName} testId={testId} />
    </>
}



export const TextField = ({
    displayName,
    name,
    value,
    testId,
    handleChange,
    required = true,
    disabled = false
}: TextFieldProps) => {
    const [inputValue, setInputValue] = useState<string>('')

    useEffect(() => {
        value ? setInputValue(value.toString()) : {}
    }, [value])

    const debouncedHandleChange = useDebouncedCallback((e) => {
        handleChange(e)
    }, 400)

    const handleInputChange = (e) => {
        setInputValue(e.target.value)
        debouncedHandleChange(e)
    }

    return (
        <Form.Group as={Col}>
            <FloatingLabel label={displayName} className="mb-3">
                <Form.Control
                    type={'text'}
                    data-testid={testId}
                    name={name}
                    onChange={handleInputChange}
                    required={required}
                    placeholder={displayName}
                    value={inputValue ? inputValue : ''}
                    disabled={disabled}
                />
                <RequiredPrompt required={required} fieldName={displayName} testId={testId} />
            </FloatingLabel>
        </Form.Group>
    )
}

const DealSection = ({ onChange, deals, deal }: DealFormProps) => {

    const [dealState, setDealState] = useState<DealModel>()

    useEffect(() => {
        setDealState(deal)
    }, [deal])

    function handleDealChange(e: any) {
        const deal: Deal = deals.find(d => d.id === e.target.value)!
        setDealState(deal)
        onChange(deal)
    }

    const dealOptions: Array<BasicEntity> = deals.map(function (deal): BasicEntity {
        return { id: deal.id, name: deal.properties.dealname }
    })

    return <>
        <FormSection title={'Deal'}>
            <SelectField displayName={'Deal'} name={'dealId'} testId={'dealId'} handleChange={handleDealChange}
                options={dealOptions} value={dealState?.id} />
        </FormSection>
    </>
}

const CustomerSection = ({ onChange, customer }: CustomerFormProps) => {
    const [customerState, setCustomerState] = useState<Partial<CustomerModel>>(customer || {})

    function handleChange(e: any) {
        setCustomerState(prevState => {
            return {
                ...prevState,
                [e.target.name]: e.target.value
            }
        })
    }

    useEffect(() => {
        onChange(customerState)
    }, [customerState])

    return <FormSection title={'Customer'}>
        <TextField displayName={'Name'} name={'name'} testId={'customerName'} handleChange={handleChange} value={customer?.name} />
        {AddressFields(handleChange, customer, 'customer')}
    </FormSection>
}

const CommercialContactSection = ({ onChange, commercialContact }: CommercialContactFormProps) => {

    const [commercialContactState, setCommercialContactState] = useState<Partial<CommercialContactModel>>({})

    useEffect(() => {
        onChange(commercialContactState)
    }, [commercialContactState])


    function handleChange(e: any) {
        setCommercialContactState(prevState => {
            const state = {
                ...prevState,
                [e.target.name]: e.target.value
            }
            setCommercialContactState(state)
            return state
        }
        )
    }
    return <FormSection title={'Commercial Contact'}>
        <TextField displayName={'Name'} name={'name'} testId={'commercialContactName'} handleChange={handleChange} value={commercialContact?.name} required={true} />
        <TextField displayName={'Phone 1'} name={'phone1'} testId={'commercialContactPhone1'} handleChange={handleChange} value={commercialContact?.phone1} required={true} />
        <TextField displayName={'Phone 2'} name={'phone2'} testId={'commercialContactPhone2'} handleChange={handleChange} value={commercialContact?.phone2} required={false} />
        <TextField displayName={'Email'} name={'email'} testId={'commercialContactEmail'} handleChange={handleChange} value={commercialContact?.email} required={true} />
    </FormSection>
}

const ContractSection = ({ onChange, contract }: ContractFormProps) => {

    const [customerState, setCustomerState] = useState<Partial<ContractModel>>({})

    function handleDateChange(e: any) {
        const parsedDate = new Date(e.target.value)

        if (!isNaN(parsedDate.getTime())) {
            const newCustomerState = {
                ...customerState,
                [e.target.name]: parsedDate
            }
            setCustomerState(newCustomerState)
            onChange(newCustomerState)
        }
    }

    function handleNumberChange(e: any) {
        const newCustomerState = {
            ...customerState,
            [e.target.name]: Number(e.target.value)
        }

        setCustomerState(newCustomerState)
        onChange(newCustomerState)
    }

    return <FormSection title={'Contract'}>
        <NumberField displayName={'Revenue share'} name={'revenueShare'} testId={'revenueShare'} placeholder={'0.4'}
            min={'0'} max={'1'} step={'.1'} handleChange={handleNumberChange}
            value={contract?.revenueShare} />

        <Row>
            <DateField displayName={'Start date'} name={'startDate'} testId={'contractStartDate'}
                handleChange={handleDateChange} value={contract?.startDate} />
            <DateField displayName={'End date'} name={'endDate'} testId={'contractEndDate'}
                handleChange={handleDateChange} value={contract?.endDate} />
        </Row>
    </FormSection>
}

function AddressFields(handleChange: (e: any) => void, address: Partial<AddressModel> | undefined, idPrefix: string) {
    return <>
        <TextField displayName={'Line 1'} name={'line1'} testId={`${idPrefix}_addressLine1`} handleChange={handleChange}
            value={address?.line1} />
        <TextField displayName={'Line 2'} name={'line2'} testId={`${idPrefix}_addressLine2`} handleChange={handleChange}
            required={false} value={address?.line2} />
        <Row>
            <TextField displayName={'City'} name={'city'} testId={`${idPrefix}_city`} handleChange={handleChange}
                value={address?.city} />
            <TextField displayName={'County'} name={'county'} testId={`${idPrefix}_county`} handleChange={handleChange}
                value={address?.county} />
            <TextField displayName={'Country'} name={'country'} testId={`${idPrefix}_country`} handleChange={handleChange}
                value={address?.country} />
            <TextField displayName={'Post code'} name={'postCode'} testId={`${idPrefix}_postcode`} handleChange={handleChange}
                value={address?.postCode} />
        </Row>
    </>
}

const BuildingSection = ({ onChange, building, orgs }: BuildingFormProps) => {

    const [state, setState] = useState<Partial<BuildingModel>>(building || {})

    useEffect(() => {
        if (building) {
            setState(building)
        }
    }, [building])

    useEffect(() => {
        if (!equal(building, state)) {
            onChange(state)
        }
    }, [state])

    function handleChange(e: any) {
        setState(prevState => {
            return {
                ...prevState,
                [e.target.name]: e.target.value
            }
        })

    }

    function handleOrg(e: SelectOption[]) {
        setState(prevState => {
            return {
                ...prevState,
                ['orgIds']: e.map(i => i.id)
            }
        })
    }

    const orgsAsOption = orgs.map(o => ({ id: o.id, label: o.name }))


    return <FormSection title={'Building'}>

        <TextField displayName={'Name'} name={'name'} testId={'buildingName'} handleChange={handleChange}
            value={building?.name} />

        <MultiSelect label={'Organisations'} data-testid={'building_org_ids'}
            onChange={handleOrg}
            initialValue={orgsAsOption.filter(o => state.orgIds?.includes(o.id))}
            options={orgsAsOption}
            required={true}
        />

        {AddressFields(handleChange, building, 'building')}
    </FormSection>
}


export interface SelectOption { label: string, id: string }


const ControlStrategy = ({ onChange, controlStrategy }: ControlStrategyFormProps) => {

    const [state, setState] = useState<Partial<ControlStrategyModel>>({})

    function handleNumberChange(e: any) {
        const newState = {
            ...state,
            [e.target.name]: Number(e.target.value)
        }
        setState(newState)
        onChange(newState)
    }

    return <FormSection title={'Control Strategy'}>
        <NumberField displayName={'Max total duration (minutes)'} name={'maxTotalDurationMinutes'} testId={'maxTotal'}
            min={'0'} handleChange={handleNumberChange} value={controlStrategy?.maxTotalDurationMinutes} />
        <NumberField displayName={'Max run duration (minutes)'} name={'maxRunDuration'} testId={'maxRun'} min={'0'}
            handleChange={handleNumberChange} value={controlStrategy?.maxRunDuration} />
        <NumberField displayName={'Max number of events per day'} name={'maxNumEvents'} testId={'maxNumEvents'}
            min={'0'} handleChange={handleNumberChange} value={controlStrategy?.maxNumEvents} />
        <NumberField displayName={'Saving (kW)'} name={'saving'} testId={'savingKw'} min={'0'}
            handleChange={handleNumberChange} value={controlStrategy?.saving} />
        <NumberField displayName={'Recovery Duration (minutes)'} name={'recoveryDuration'} testId={'recoveryDuration'}
            min={'0'} step={'15'} handleChange={handleNumberChange} value={controlStrategy?.recoveryDuration} />
        <NumberField displayName={'Ramp Up/Down time (minutes)'} name={'rampUpDownTime'} testId={'rampTimeMins'}
            min={'0'} handleChange={handleNumberChange} value={controlStrategy?.rampUpDownTime} />
    </FormSection>
}

const TariffSection = ({ onChange, suppliers, tariff }: TariffFormProps) => {
    const [state, setState] = useState<Partial<TariffModel>>(tariff ? tariff : {})

    useEffect(() => {
        tariff && setState(tariff)
    }, [tariff])

    function handleChange(e: any) {
        const newState = {
            ...state,
            [e.target.name]: e.target.value
        }
        setState(newState)
        onChange(newState)
    }

    function handleDateChange(e: any) {
        const parsedDate = new Date(e.target.value)

        if (!isNaN(parsedDate.getTime())) {
            const newState = {
                ...state,
                [e.target.name]: parsedDate
            }
            setState(newState)
            onChange(newState)
        }
    }

    function handleNumberChange(e: any) {
        const newState = {
            ...state,
            [e.target.name]: Number(e.target.value)
        }
        setState(newState)
        onChange(newState)
    }

    return <FormSection title={'Tariff'}>
        <SelectField displayName={'Fee type'} name={'transmissionFeeType'} testId={'feeType'}
            handleChange={handleChange}
            options={[{ id: 'PassThrough', name: 'PassThrough' }, { id: 'Fixed', name: 'Fixed' }]}
            value={state.transmissionFeeType} />
        <SelectField displayName={'Supplier'} name={'supplierId'} testId={'supplierId'}
            handleChange={handleNumberChange} options={suppliers} value={state.supplierId} />
        <Row>
            <DateField displayName={'Start date'} name={'startDate'} testId={'tariffStartDate'}
                handleChange={handleDateChange} value={state.startDate} />
            <DateField displayName={'End date'} name={'endDate'} testId={'tariffEndDate'}
                handleChange={handleDateChange} value={state.endDate} />
        </Row>
        <NumberField displayName={'Price per kWh (£)'} name={'pricePerKWh'} testId={'tariffPricePerKwh'} min={'0'}
            step={'0.01'} handleChange={handleNumberChange} value={state.pricePerKWh} />
    </FormSection>
}

const MeterSection = ({ onChange, mops, meter, oneBasedIndex }: MeterFormProps) => {

    const [state, setState] = useState<Partial<MeterModel>>(meter)

    useEffect(() => {
        setState(meter)
    }, [meter])

    function handleChange(e: any) {
        const newState = {
            ...state,
            [e.target.name]: e.target.value
        }
        setState(newState)
        onChange(newState)
    }

    function handleNumberChange(e: any) {
        const newState = {
            ...state,
            [e.target.name]: Number(e.target.value)
        }
        setState(newState)
        onChange(newState)
    }

    return <FormSubSection title={'Meter ' + oneBasedIndex}>
        <TextField displayName={'Serial Number'} name={'serialNumber'} testId={`meter_${oneBasedIndex}_serialNumber`}
            handleChange={handleChange} value={meter?.serialNumber} />
        <TextField displayName={'MPAN'} name={'mpan'} testId={`meter_${oneBasedIndex}_mpan`} handleChange={handleChange}
            value={meter?.mpan} />
        <SelectField displayName={'MOP'} name={'mopId'} testId={`meter_${oneBasedIndex}_mopId`} handleChange={handleNumberChange}
            options={mops} value={meter?.mopId} />
    </FormSubSection>
}

const MetersSection = ({ onChange, mops, meters }: MetersFormProps) => {
    const [state, setState] = useState<Array<Partial<MeterModel>>>(meters)

    function handleMeterChange(zeroBasedIndex: number) {
        return (newMeter: Partial<MeterModel>) => {
            const updatedMeters = [...state]
            updatedMeters[zeroBasedIndex] = newMeter
            setState(updatedMeters)
            onChange(updatedMeters)
        }
    }

    useEffect(() => {
        setState(meters)
    }, [meters])

    return <FormSection title={'Meters'}>
        {
            meters.map((meter, index) =>
                <MeterSection key={index} onChange={handleMeterChange(index)} mops={mops} meter={meter}
                    oneBasedIndex={index + 1} />
            )
        }
    </FormSection>
}

function joinState(
    customer: Partial<CustomerModel>,
    commercialContact: Partial<CommercialContactModel>,
    contract: Partial<ContractModel>,
    building: Partial<BuildingModel>,
    controlStrategy: Partial<ControlStrategyModel>,
    tariff: Partial<TariffModel>,
    meters: Array<Partial<MeterModel>>,
    deal?: DealModel,
): Partial<OnboardingFormModel> {
    return {
        deal,
        customer,
        commercialContact,
        contract,
        building,
        controlStrategy,
        tariff,
        meters,
    }
}

export function transformOnboardingFormIntoModel(data: Partial<OnboardingFormModel>): SubmissionModel {

    const hubSpotDealId: string | undefined = data.deal?.id
    return {
        hubSpotDealId,
        customer: {
            name: data.customer!.name!,
            address: {
                line1: data.customer!.line1!,
                line2: data.customer!.line2,
                city: data.customer!.city!,
                postCode: data.customer!.postCode!,
                county: data.customer!.county!,
                country: data.customer!.country!,
            },
            commercialContact: {
                name: data.commercialContact!.name!,
                phone1: data.commercialContact!.phone1!,
                phone2: data.commercialContact!.phone2,
                email: data.commercialContact!.email!,
            }
        },
        contract: {
            startDate: new LocalDateOld(data.contract!.startDate!),
            endDate: new LocalDateOld(data.contract!.endDate!),
            revenueShare: data.contract!.revenueShare!,
        },
        building: {
            name: data.building!.name!,
            orgIds: data.building!.orgIds!,
            controlStrategy: {
                maxTotalDurationMinutes: data.controlStrategy!.maxTotalDurationMinutes!,
                maxRunDuration: data.controlStrategy!.maxRunDuration!,
                maxNumEvents: data.controlStrategy!.maxNumEvents!,
                saving: data.controlStrategy!.saving!,
                recoveryDuration: data.controlStrategy!.recoveryDuration!,
                rampUpDownTime: data.controlStrategy!.rampUpDownTime!,
                bmsStrategyId: 1,
            },
            address: {
                line1: data.building!.line1!,
                line2: data.building!.line2,
                city: data.building!.city!,
                postCode: data.building!.postCode!,
                county: data.building!.county!,
                country: data.building!.country!,
            }
        },
        tariff: {
            transmissionFeeType: data.tariff!.transmissionFeeType!,
            supplierId: data.tariff!.supplierId!,
            startDate: new LocalDateOld(data.tariff!.startDate!),
            endDate: new LocalDateOld(data.tariff!.endDate!),
            pricePerKWh: data.tariff!.pricePerKWh!
        },
        meters: data.meters!.map((m: MeterModel) =>
        ({
            mopId: m.mopId!,
            mpan: m.mpan!,
            serialNumber: m.serialNumber!,
        })
        )
    }
}

export const OnboardingForm = ({
    handleSubmit,
    retrieveDeals,
    retrieveSuppliers,
    retrieveMops,
    retrieveOrgs,
}: OnboardingFormProps) => {

    const [deal, setDeal] = useState<DealModel>()
    const [customer, setCustomer] = useState<Partial<CustomerModel>>({})
    const [commercialContact, setCommercialContact] = useState<Partial<CommercialContactModel>>({})
    const [contract, setContract] = useState<Partial<ContractModel>>({})
    const [building, setBuilding] = useState<Partial<BuildingModel>>({})
    const [controlStrategy, setControlStrategy] = useState<Partial<ControlStrategyModel>>({})
    const [tariff, setTariff] = useState<Partial<TariffModel>>({})
    const [meters, setMeters] = useState<Array<Partial<MeterModel>>>([{}])

    const [validated, setValidated] = useState(false)

    const [deals, setDeals] = useState<Deal[]>([])
    const [suppliers, setSuppliers] = useState<Supplier[]>([])
    const [mops, setMops] = useState<Mop[]>([])
    const [orgs, setOrgs] = useState<Organisation[]>([])

    const [validationErrorMessage, setValidationErrorMessage] = useState<string>()
    const [successMessage, setSuccessMessage] = useState<string>()

    const [formDOM, setFormDOM] = useState<HTMLFormElement | null>(null)

    async function handleSubmitInternal(event: any) {
        const form = event.currentTarget
        event.preventDefault()
        setValidated(true)
        if (form.checkValidity() === true) {
            setValidationErrorMessage(undefined)

            try {
                await handleSubmit(transformOnboardingFormIntoModel(joinState(
                    customer,
                    commercialContact,
                    contract,
                    building,
                    controlStrategy,
                    tariff,
                    meters,
                    deal,
                )))
                setSuccessMessage("Building successfully entered.")
            } catch (e) {
                setValidationErrorMessage("An error occurred - #ask-tech for support. " + e.message)
            }
        } else {
            setValidationErrorMessage("Please enter valid onboarding details.")
        }
    }

    function resettingSuccessAfter(stateSetter: (newState: unknown) => void) {
        return (newState: unknown) => {
            stateSetter(newState)
            setSuccessMessage(undefined)
        }
    }

    function onChangeDeal(newState: DealModel) {
        formDOM?.reset()

        setDeal(newState)
        setCustomer({
            name: newState.properties.customer_name,
            line1: newState.properties.customer_address_line_1,
            line2: newState.properties.customer_address_line_2,
            city: newState.properties.customer_address_city,
            postCode: newState.properties.customer_address_postcode,
            county: newState.properties.customer_address_county,
            country: newState.properties.customer_address_country,
        })
        setCommercialContact({
            name: newState.properties.customer_commercial_contact_name,
            phone1: newState.properties.customer_commercial_contact_phone_1,
            phone2: newState.properties.customer_commercial_contact_phone_2,
            email: newState.properties.customer_commercial_contact_email,
        })
        setBuilding({
            name: newState.properties.building_name,
            line1: newState.properties.building_address_line_1,
            line2: newState.properties.building_address_line_2,
            city: newState.properties.building_address_city,
            postCode: newState.properties.building_address_postcode,
            county: newState.properties.building_address_county,
            country: newState.properties.building_address_country,

        })
        setContract({
            startDate: new Date(newState.properties.contract_start_date),
            endDate: new Date(newState.properties.contract_end_date),
            revenueShare: Number(newState.properties.contract_oaktree_power_revenue_share),
        })
        setTariff({
            transmissionFeeType: TransmissionFeeType(newState.properties.energy_tariff_fee_type),
            supplierId: Number(newState.properties.energy_tariff_supplier),
            startDate: new Date(newState.properties.energy_tariff_start_date),
            endDate: new Date(newState.properties.energy_tariff_end_date),
            pricePerKWh: Number(newState.properties.energy_tariff_price_per_kw_in_gbp),
        })
        setMeters(newState.properties.meters.map((meterApiModel, index) => ({
            serialNumber: meters[index]?.serialNumber,
            mpan: meterApiModel.mpan,
            mopId: Number(meterApiModel.mopId)
        })))

    }

    useEffect(() => {
        retrieveDeals()
            .then(deals => setDeals(deals))
        retrieveSuppliers()
            .then(suppliers => setSuppliers(suppliers))
        retrieveMops()
            .then(mops => setMops(mops))
        retrieveOrgs()
            .then(orgs => setOrgs(orgs))
    }, [retrieveDeals, retrieveSuppliers, retrieveMops, retrieveOrgs])

    return <Form noValidate validated={validated} onSubmit={handleSubmitInternal} ref={form => setFormDOM(form)}>
        <DealSection onChange={resettingSuccessAfter(onChangeDeal)} deals={deals} deal={deal} />
        <CustomerSection onChange={resettingSuccessAfter(setCustomer)} customer={customer} />
        <CommercialContactSection onChange={resettingSuccessAfter(setCommercialContact)} commercialContact={commercialContact} />
        <ContractSection onChange={resettingSuccessAfter(setContract)} contract={contract} />
        <BuildingSection onChange={resettingSuccessAfter(setBuilding)} building={building} orgs={orgs} />
        <ControlStrategy onChange={resettingSuccessAfter(setControlStrategy)} controlStrategy={controlStrategy} />
        <TariffSection onChange={resettingSuccessAfter(setTariff)} suppliers={suppliers} tariff={tariff} />
        <MetersSection onChange={resettingSuccessAfter(setMeters)} mops={mops} meters={meters} />
        <button data-testid='submit-data' type='submit' className='btn btn-primary'>Submit</button>
        {validationErrorMessage &&
            <Alert data-testid='validationError' variant='danger'>{validationErrorMessage}</Alert>}
        {successMessage && <Alert data-testid='successMessage' variant='success'>{successMessage}</Alert>}
    </Form>
}
