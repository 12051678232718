import React, {useEffect, useState} from "react";
import {Row} from "react-bootstrap";
import {NumberField} from "./OnboardingForm";
import {DurationInMinutes, MfrrParameters} from "../client/EventScheduleClient";

interface MfrrBiddingWindowEventProps {
    setMfrrParameters: (MfrrParameters) => void,
    initialMfrrParameters?: MfrrParameters
}

export const MfrrBiddingWindowEvent = ({setMfrrParameters, initialMfrrParameters}:MfrrBiddingWindowEventProps) => {

    const [parameters, setParameters] = useState<MfrrParameters>(initialMfrrParameters ?? {
        bidDuration: new DurationInMinutes(15),
        bidPriceInEurosPerMegawattHours: 100.0,
        bidQuantityInMegawatts: 1,
        maxActivations: 1
    })

    useEffect(() => {
        setMfrrParameters(parameters)
    }, [setMfrrParameters, parameters])

    function handleSetDurationParameter(e) {
        parameters.bidDuration = new DurationInMinutes(parseInt(e.target.value))
        setParameters({... parameters})
    }

    function handleSetParameter(name:string) {
        return function (e: any) {
            parameters[name] = parseInt(e.target.value)
            setParameters({... parameters})
        }
    }

    return <div data-testid={'biddingWindow'}>
        <Row>
            {<NumberField displayName={'Bid Duration'} min={"0"} required={true} handleChange={handleSetDurationParameter} name={'bidDuration'} testId={'bidDuration'} value={parameters.bidDuration.minutes}/>}
            {<NumberField displayName={'Bid Price In Euros Per Megawatt-Hours'} min={"0"} required={true} handleChange={handleSetParameter('bidPriceInEurosPerMegawattHours')} name={'bidPriceInEurosPerMegawattHours'} testId={'bidPriceInEurosPerMegawattHours'} value={parameters.bidPriceInEurosPerMegawattHours}/>}
        </Row>
        <Row>
            {<NumberField displayName={'Bid Quantity In Megawatts'} min={"1"} required={true} handleChange={handleSetParameter('bidQuantityInMegawatts')} name={'bidQuantityInMegawatts'} testId={'bidQuantityInMegawatts'} value={parameters.bidQuantityInMegawatts}/>}
            {<NumberField displayName={'Max Activations'} min={"1"} required={true} handleChange={handleSetParameter('maxActivations')} name={'maxActivations'} testId={'maxActivations'} value={parameters.maxActivations}/>}
        </Row>

    </div>
}
